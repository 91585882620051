import Component from '../core/Component'
import EventBus from '../core/EventBus'
import GoogleMap from './GoogleMap'

const params = ["open", "vingette", "gastro", "wash", "fuelE5", "lpg", "adBlue", "toll", "exchange", "mediPoint"]

export default class GasMap extends Component {
    constructor(element) {
        super(element)
        this.options = {}

        if (this.element.dataset.data) {
            this.options.data = JSON.parse(this.element.dataset.data)
        }

        this.map = new GoogleMap(this.element, this.options.data)

    }

    prepare() {
        EventBus.on('marker:click', this.handleMarkerClick)
        EventBus.on('mapFilter:change', this.filterChange)
        EventBus.on('mapFilter:submit', this.filterSubmit)
    }

    destroy() {
        if (this.map) {
            this.map.destroy()
        }
        EventBus.off('marker:click', this.handleMarkerClick)
        EventBus.off('mapFilter:change', this.filterChange)
        EventBus.off('mapFilter:submit', this.filterSubmit)
    }

    handleMarkerClick = async ({ marker }) => {
        const data = marker.options.data
        const modal = Component.getFromElement("modal")

        if (modal) {
            const promise = await this.makeRequest(data.url,modal)

            modal.open()
        }
    }

    makeRequest(url, modal) {
        if (this.request) {
            this.request.abort()
        }

        this.request = $.ajax({
            url: url,
            type: "POST",
            dataType: "html",
            success: data => {
                modal.setContent(data)
            },

            error: error => {
                console.error("Chyba requestu.")
            }
        });

        return this.request
    }

    filterSubmit = (data) => {
        const ADRESS = data.adress,
              DISTANCE = data.distance,
              ITEMS = data.items

        let filteredItems = this.options.data.filter(item =>{
            let isValid = true;

            params.forEach(key=>{
                if (ITEMS[key] && !item[key]) {
                    isValid = false
                }
            })

            return isValid
        })


        this.map.findArea(ADRESS,DISTANCE,filteredItems)
    }
}
